import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from "./Component/NavBar/Header";
import Home from './Pages/Home';
import Stocks from './Pages/Stocks';
import StockDetails from './Pages/StockDetails';
import Footer from './Component/Footer/Footer';
import About from './Pages/About';
import Contact from './Pages/Contact';
import NewArrival from './Pages/NewArrival';
import MarqueeComponent from './Component/Marquee/MarqueeComponent';
import Account from "./Pages/Account";
import Profiles from "./Component/Profile/Profiles";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<><MarqueeComponent /><Header /> <Home /> <Footer /></>} />
          <Route path='/about' element={<><MarqueeComponent /><Header /> <About /> <Footer /></>} />
          <Route path='/:maincategory' element={<><MarqueeComponent /><Header /> <Stocks /> <Footer /></>} />
          <Route path="/account/:page" element={<><Account /></>} />
          <Route path='/stock-details/:stockSlugs' element={<><MarqueeComponent /><Header /> <StockDetails /> <Footer /></>} />
          <Route path='/new-arrival' element={<><MarqueeComponent /><Header /> <NewArrival /> <Footer /></>} />
          <Route path='/contact' element={<><MarqueeComponent /><Header /> <Contact /><Footer /></>} />
          <Route path='/profiles' element={<><MarqueeComponent /><Header /> <Profiles /><Footer /></>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
