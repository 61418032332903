import React, { useEffect, useState } from 'react';
import "../Css/Stocks.css";
import { HiMenuAlt1 } from "react-icons/hi";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import rrp from "../Images/Stock/rrp.png";
import StockData from '../Component/StockData';
import { Link, useParams } from 'react-router-dom';
import { Select, Space } from 'antd';
import Form from 'react-bootstrap/Form';
import { MdSearch } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import axios from 'axios';

const Stocks = () => {
    const { maincategory } = useParams();
    const [stockType, setStockType] = useState("");

    const [stockData, setStockData] = useState([]);
    console.log("stockData", stockData)

    useEffect(() => {
        if (maincategory == "delisted") {
            setStockType("Delisted");
            fetchCategoryData("Delisted");
        } else if (maincategory == "unlisted") {
            setStockType("Unlisted");
            fetchCategoryData("unlisted");
        } else if (maincategory == "preipo") {
            setStockType("IPO");
            fetchCategoryData("IPO");
        }
    }, [maincategory]);




    const fetchCategoryData = async (type) => {
        try {
            const response = await axios.get(`https://skdm.in/server/bhavinjavari/stock.php?action=stockFetchnew&stock_type=${type}`);
            setStockData(response.data.list);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    return (
        <>
            <section className="ProductList-Section-1 stock-banner">
                <div className="container">
                    <div className="ProductList-Section-con">
                        <div className="ProductList-Section-Text">
                            <div className="ProductList-Section-NavPage">
                                <Link to="/">
                                    <span>
                                        <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            strokeWidth={0}
                                            viewBox="0 0 1024 1024"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z" />
                                        </svg>
                                        Home
                                    </span>
                                </Link>
                                <h3>
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 24 24"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path fill="none" d="M0 0h24v24H0V0z" />
                                        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                                    </svg>
                                </h3>
                                <h2>{stockType && stockType} List</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='men-section-filter-con'>
                <div className='men-section-filert'>
                    <div className='men-section-main-filert'>
                        <div className='men-section-main-filert-details'>
                            <Form.Select aria-label="Default select example">
                                <option>All Sector</option>
                                <option value="1">Auto 3 wheelers</option>
                                <option value="2">Bank</option>
                                <option value="3">Chemical Industry</option>
                            </Form.Select>
                        </div>
                    </div>
                    <div className='stock-section-search-main-con'>
                        <div className='search-section-main-con'>
                            <div className='search-section-con'>
                                <div className='search-icons-con'>
                                    <MdSearch />
                                </div>
                                <div className='search-bar-main-con'>
                                    <input placeholder='Search' />
                                </div>
                                <div className='search-cancel-con'>
                                    <RxCross2 />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='men-section-main-sort-by'>
                        <div className='men-section-main-filert-details'>
                            <Form.Select aria-label="Default select example">
                                <option>sort by</option>
                                <option value="1">high to low</option>
                                <option value="2">low to high</option>
                            </Form.Select>
                        </div>
                    </div>
                </div>
            </section>

            <section className='stock-section-filter-main-con'>
                <div className='container'>
                    <div className='stock-section-filter-con'>
                        <div className=''>

                        </div>
                    </div>
                </div>
            </section>

            <section className='stocks-section-2-con'>
                <div className='container'>
                    <div className='stocks-section-main-con'>
                        <div className='row'>
                            {
                                stockData.map((elem) => {
                                    const { base_price, image, name, price, stock_type, slug } = elem;
                                    return (
                                        <div className='col-md-4' key={slug}>
                                            <Link to={`/stock-details/${slug}`}>
                                                <div className='stock-card-main-con'>
                                                    <div className='stock-card-image-con'>
                                                        <img src={elem.image} alt='nse' />
                                                    </div>
                                                    <div className='stock-card-details-con'>
                                                        <h3>{elem.name}</h3>
                                                        <h4>{elem.base_price}</h4>
                                                        <div className='stock-card-details-info-con'>
                                                            <h5>( {elem.profit} )</h5>
                                                            <h5>( {elem.profitpercentage} )</h5>
                                                            <h6>{elem.some}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Stocks;
