import React, { useEffect, useState } from 'react';
import "../Css/NewArrival.css";
import StockData from '../Component/StockData';
import axios from 'axios';
import { Link } from 'react-router-dom';

const NewArrival = () => {

    // pagination start

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(12);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        const debounceData = setTimeout(() => {
            fetchCategoryData(page, limit);
        }, 300);

        return () => clearTimeout(debounceData);
    }, [page, limit]);

    // pagination end

    const [newStocks, setNewStocks] = useState([])
    const fetchCategoryData = async (page, limit) => {
        try {
            const response = await axios.get(`https://skdm.in/server/bhavinjavari/stock.php?action=stockFetchnewarival&limit=${limit}&page=${page}`);
            if (response) {
                setNewStocks(response.data.list);
                setTotal(response.data.total);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    return (
        <>
            <section className='new-arrival-section-1'>
                <div className='container'>
                    <div className='new-arrival-section-main-con'>
                        <div className='home-section-3-heading-con'>
                            <h2><span>New</span> Arrival</h2>
                            <p>"Discover the latest trends with our fresh arrivals! Dive into new and exclusive stocks, offering the best picks just for you. Don't miss out—explore now and stay ahead!"</p>
                        </div>

                        <div className='new-arrival-section-details-main-con'>
                            <div className='row'>
                                {
                                    newStocks.map((elem) => {

                                        const { base_price, image, name, price, stock_type, slug } = elem

                                        return (
                                            <>
                                                <div className='col-md-4'>
                                                    <div className='stock-card-main-con-card-con'>
                                                        <Link to={`/stock-details/${slug}`}>
                                                            <div className='stock-card-main-con'>
                                                                <div className='stock-card-image-con'>
                                                                    <img src={image} alt='nse' />
                                                                </div>
                                                                <div className='stock-card-details-con'>
                                                                    <h3>{name}</h3>
                                                                    <h4>₹{price}</h4>
                                                                    <div className='stock-card-details-info-con'>
                                                                        <h5>( {elem.profit} )</h5>
                                                                        <h5>( {elem.profitpercentage}% )</h5>
                                                                        <h6>{elem.some}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div >
                    </div>
                </div>
            </section>
        </>
    )
}

export default NewArrival