import React, { useEffect, useState } from 'react';
import "../Css/StockDetails.css";
import { Button, Form, Input } from 'antd';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

const StockDetails = () => {

    const { stockSlugs } = useParams();
    const [stockDetails, setStockDetails] = useState(null);
    const [companyDetails, setCompanyDetails] = useState(null)
    const [loading, setLoading] = useState(true); // Add loading state
    const [form] = Form.useForm(); // Create a form instance
    useEffect(() => {
        const fetchCategoryData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`https://skdm.in/server/bhavinjavari/stock.php?action=fetchStockDataBySlug&slug=${stockSlugs}`);
                setStockDetails(response?.data?.stocks[0]?.stock);
                setCompanyDetails(response?.data?.stocks[0]?.company_info);
                console.log("response?.data?.stocks[0]", response?.data?.stocks[0]);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        const renderTime = setTimeout(() => {
            fetchCategoryData();
        }, 500);

        return () => clearTimeout(renderTime);
    }, [stockSlugs]);

    useEffect(() => {
        if (companyDetails && stockDetails) {
            form.setFieldsValue({
                price: stockDetails.price, // Set the price in the form
                Lot_Size: stockDetails.Lot_Size,
            });
        }
    }, [companyDetails, form]); // Update form when companyDetails change

    const handleQuantityChange = (e) => {
        const quantity = e.target.value;
        const price = form.getFieldValue('price');

        if (price && quantity) {
            const totalPrice = parseFloat(price) * parseInt(quantity, 10);
            form.setFieldsValue({ totalprice: totalPrice.toFixed(2) });
        } else {
            form.setFieldsValue({ totalprice: '' });
        }
    };

    if (loading) {
        return <div>Loading...</div>; // Show a loading indicator
    }

    if (!stockDetails) {
        return <div>Stock details not found.</div>; // Handle case where stockDetails is null
    }

    const handleBuySell = async (action) => {
        try {
            const formData = new FormData();
            const formValues = form.getFieldsValue();

            for (const key in formValues) {
                formData.append(key, formValues[key]);
            }

            formData.append('action', action);
            formData.append('stockSlug', stockSlugs); // Add stock slug to the form data

            const response = await axios.post(
                'YOUR_API_ENDPOINT', // Replace with your API endpoint URL
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            console.log('API Response:', response.data);
            alert(`Successfully ${action}ed`); // Example alert
        } catch (error) {
            console.error('API Error:', error);
            alert(`Failed to ${action}`); // Example alert
        }
    };


    return (
        <>
            <section className="ProductList-Section-1 details-banner">
                <div className="container">
                    <div className="ProductList-Section-con">
                        <div className="ProductList-Section-Text">
                            <div className="ProductList-Section-NavPage">
                                <span>
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 1024 1024"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z" />
                                    </svg>
                                    Home
                                </span>
                                <h3>
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 24 24"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path fill="none" d="M0 0h24v24H0V0z" />
                                        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                                    </svg>
                                </h3>
                                <h2>Stock Details</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='stock-details-section-2'>
                <div className='stock-details-section-2-con'>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='stock-details-section-2-details-main-con-Info'>
                                <div className='stock-details-section-2-details-image-name-con'>
                                    <div className='stock-details-section-2-details-image'>
                                        <img src={stockDetails?.image} alt='rrp' />
                                    </div>
                                    <h2>{companyDetails && companyDetails?.company_name}</h2>
                                    <div className='stock-details-section-2-details-price'>
                                        <h3>₹ {stockDetails && stockDetails?.price}</h3>
                                        <h4>(₹ {stockDetails && stockDetails?.base_price})</h4>
                                        <h4>(100.00%)</h4>
                                        <h5>3Y</h5>
                                    </div>
                                </div>

                                <div className='stocks-details-section-2-main-con'>
                                    {/* <div className='stocks-details-section-2-con'>
                                        <h2>About {stockDetails?.stock?.name} {stockDetails?.stock?.stock_type} Shares</h2>
                                        <h3><span>1. Introduction:</span>  {stockDetails?.aboutStock?.[0]?.Introduction} </h3>
                                        <h3><span>2. Previous Ownership and Acquisitions:</span>{stockDetails?.aboutStock?.[0]?.Previous_Ownership_and_Acquisitions}</h3>
                                        <h3><span>3. Post-Acquisition Transformation: </span> {stockDetails?.aboutStock?.[0]?.Post_Acquisition_Transformation}</h3>
                                        <h3><span>4. Financial Performance:</span>  {stockDetails?.aboutStock?.[0]?.Financial_Performance} </h3>
                                        <h3><span>5. Manufacturing Capacity Expansion:</span>  {stockDetails?.aboutStock?.[0]?.Manufacturing_Capacity_Expansion} </h3>
                                        <h3><span>6. Shareholding and Valuation: </span> {stockDetails?.aboutStock?.[0]?.Shareholding_and_Valuation} </h3>
                                        <h3><span>7. Management and Board Structure: </span> {stockDetails?.aboutStock?.[0]?.Management_and_Board_Structure} </h3>
                                        <h3><span>8. Future Prospects: </span> {stockDetails?.aboutStock?.[0]?.Future_Prospects} </h3>
                                        <h3><span>9. Conclusion: </span>  {stockDetails?.aboutStock?.[0]?.Conclusion} </h3>
                                    </div> */}
                                    <p>{stockDetails?.description}</p>
                                </div>
                                <div className='stocks-details-section-2-fundamental'>
                                    <h2>Fundamentals</h2>
                                    <div className='stocks-details-section-2-fundamental-details'>
                                        <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1'>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>Company Name</h3>
                                                    <h4>{companyDetails && companyDetails?.company_name}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>Scrip Name</h3>
                                                    <h4>{companyDetails?.scrip_name} Shares</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>Last Traded Price</h3>
                                                    <h4>₹ {companyDetails && companyDetails?.last_traded_price}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>Sector</h3>
                                                    <h4>{companyDetails && companyDetails?.sector}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>PAN No.</h3>
                                                    <h4>{companyDetails && companyDetails?.pan_no}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>Face Value</h3>
                                                    <h4>{companyDetails && companyDetails?.face_value}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>No. of Outstanding Shares</h3>
                                                    <h4>₹ {companyDetails && companyDetails?.outstanding_shares}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>EPS</h3>
                                                    <h4>{companyDetails && companyDetails?.eps}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>PE ratio</h3>
                                                    <h4>{companyDetails && companyDetails?.pe_ratio}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>Market Capitalization</h3>
                                                    <h4>{companyDetails && companyDetails?.market_capitalization}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>Book value</h3>
                                                    <h4>{companyDetails && companyDetails?.book_value}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>P/BV</h3>
                                                    <h4>{companyDetails && companyDetails?.pbv}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>ISIN No.</h3>
                                                    <h4>{companyDetails && companyDetails?.isin_no}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>Available on</h3>
                                                    <h4>{companyDetails && companyDetails?.available_on}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>ROFR Require</h3>
                                                    <h4>{companyDetails && companyDetails?.rofr_require}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>52 Week High</h3>
                                                    <h4>{companyDetails && companyDetails?.week_52_high}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>Lifetime High</h3>
                                                    <h4>{companyDetails && companyDetails?.lifetime_high}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>Day High</h3>
                                                    <h4>{companyDetails && companyDetails?.day_high}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='stock-details-section-2-right-side-main-con'>
                                <div className='stock-details-2-right-side-form'>
                                    <div className='stock-details-2-right-side-form-title'>
                                        <h3>{companyDetails && companyDetails?.company_name} {stockDetails && stockDetails?.name} Shares</h3>
                                        <h4>{stockDetails && stockDetails?.price}/- Rs</h4>
                                    </div>
                                    <div className='stock-details-2-right-side-form-price'>
                                        <div className='stock-details-2-form-price-and-quent'>
                                            <Form layout='vertical' form={form}>
                                                <Form.Item name="name" label="Name">
                                                    <Input
                                                        placeholder='Name'
                                                    />
                                                </Form.Item>
                                                <Form.Item name="email" label="Email">
                                                    <Input
                                                        placeholder='Email'
                                                    />
                                                </Form.Item>
                                                <Form.Item name="contact" label="Contact">
                                                    <Input
                                                        placeholder='Contact No.'
                                                    />
                                                </Form.Item>
                                                <Form.Item name="Lot_Size" label="Lot Size">
                                                    <Input
                                                        placeholder='Lot Size'
                                                    />
                                                </Form.Item>
                                                <Form.Item name="quantity" label="Quantity">
                                                    <Input placeholder='quantity' onChange={handleQuantityChange} />
                                                </Form.Item>

                                                <Form.Item name="price" label="Price">
                                                    <Input
                                                        placeholder='Price'
                                                    />
                                                </Form.Item>
                                                <Form.Item name="message" label="Message">
                                                    <Input.TextArea
                                                        placeholder='Message'
                                                    />
                                                </Form.Item>
                                                <Form.Item name="totalprice" label="Total Price">
                                                    <Input
                                                        placeholder='Total Price'
                                                    />
                                                </Form.Item>
                                                <Form.Item name="cdsl" label="CDSL / NSDL">
                                                    <Input
                                                        placeholder='CDSL / NSDL Number'
                                                    />
                                                </Form.Item>
                                                <Form.Item>
                                            <Button className='stock-details-buy-btn' onClick={() => handleBuySell('buy')}>
                                                Buy
                                            </Button>
                                            <Button className='stock-details-sell-btn' onClick={() => handleBuySell('sell')}>
                                                Sell
                                            </Button>
                                        </Form.Item>
                                            </Form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default StockDetails