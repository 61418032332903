import React, { useEffect, useState } from 'react';
import "../Home/HomeProduct.css";
import { Pagination, Spin } from 'antd';
import Portfolio from "../../Images/data-management.png";
import axios from 'axios';
import { Link } from 'react-router-dom';
const HomeProduct = () => {

    // pagination start

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(3);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true); // Add loading state
    useEffect(() => {
        const debounceData = setTimeout(() => {
            fetchCategoryData(page, limit);
        }, 300);

        return () => clearTimeout(debounceData);
    }, [page, limit, total]);

    // pagination end


    const [productData, setProductData] = useState([])
    const fetchCategoryData = async (page, limit) => {
        setLoading(true); // Set loading to true before fetching
        try {
            const response = await axios.get(`https://skdm.in/server/bhavinjavari/stock.php?action=stockFetch&limit=${limit}&page=${page}`);
            if (response) {
                setLoading(false); // Set loading to true before fetching
                setProductData(response.data.list);
                setTotal(response.data.total);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    console.log("productData1", productData)
    if (loading) {
        return <div className='' style={{margin:"40px"}}>
            <Spin tip="Loading" size="large">
                {/* loading...  */}
            </Spin>
        </div>; // Show a loading indicator
    }

    return (
        <>
            <section className='home-section-3'>
                <div className='container'>
                    <div className='home-section-3-con'>
                        <div className='home-section-3-heading-con'>
                            <h2>Popular <span>Unlisted</span> Shares</h2>
                            <p>"Discover Top Picks: Browse the Most Popular Unlisted Shares Sold Through Our Trusted Platform."</p>
                        </div>
                        <div className='home-section-3-filter-con-main'>
                            <div className='home-section-3-filter-con'>
                                <div className='home-section-3-filter-portfolio-con'>
                                    <div className='home-section-3-filter-portfolio-image-con'>
                                        <img src={Portfolio} alt='Portfolio' />
                                    </div>
                                    <h3>Portfolio</h3>
                                </div>
                            </div>
                        </div>
                        <div className='home-section-3-Card-main-con'>
                            <div className='row'>
                                {
                                    productData && productData.map((elem) => {
                                        const { base_price, image, name, slug, price } = elem
                                        return (
                                            <>
                                                <div className='col-lg-4 col-md-6 col-sm-12'>
                                                    <div className='stock-card-main-con-card-con'>
                                                        <Link to={`/stock-details/${slug}`}>
                                                            <div className='stock-card-main-con'>
                                                                <div className='stock-card-image-con'>
                                                                    <img src={image} alt='nse' />
                                                                </div>
                                                                <div className='stock-card-details-con'>
                                                                    <h3>{name}</h3>
                                                                    <h4>₹ {price}</h4>
                                                                    {/* <div className='stock-card-details-info-con'>
                                                                        <h5>( +0.00 )</h5>
                                                                        <h5>( 0% )</h5>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                                {/* <div className='col-md-4'>
                                    <div className='stock-card-main-con'>
                                        <div className='stock-card-image-con'>
                                            <img src={BharatNidhi} alt='nse' />
                                        </div>
                                        <div className='stock-card-details-con'>
                                            <h3>NSE India Limited Unlisted Shares</h3>
                                            <h4>₹6500</h4>
                                            <div className='stock-card-details-info-con'>
                                                <h5>( +0.00 )</h5>
                                                <h5>( 0% )</h5>
                                                <h6>15D</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='stock-card-main-con'>
                                        <div className='stock-card-image-con'>
                                            <img src={hdb} alt='nse' />
                                        </div>
                                        <div className='stock-card-details-con'>
                                            <h3>NSE India Limited Unlisted Shares</h3>
                                            <h4>₹6500</h4>
                                            <div className='stock-card-details-info-con'>
                                                <h5>( +0.00 )</h5>
                                                <h5>( 0% )</h5>
                                                <h6>15D</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='stock-card-main-con'>
                                        <div className='stock-card-image-con'>
                                            <img src={Polymatech} alt='nse' />
                                        </div>
                                        <div className='stock-card-details-con'>
                                            <h3>NSE India Limited Unlisted Shares</h3>
                                            <h4>₹6500</h4>
                                            <div className='stock-card-details-info-con'>
                                                <h5>( +0.00 )</h5>
                                                <h5>( 0% )</h5>
                                                <h6>15D</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='stock-card-main-con'>
                                        <div className='stock-card-image-con'>
                                            <img src={rrp} alt='nse' />
                                        </div>
                                        <div className='stock-card-details-con'>
                                            <h3>NSE India Limited Unlisted Shares</h3>
                                            <h4>₹6500</h4>
                                            <div className='stock-card-details-info-con'>
                                                <h5>( +0.00 )</h5>
                                                <h5>( 0% )</h5>
                                                <h6>15D</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='stock-card-main-con'>
                                        <div className='stock-card-image-con'>
                                            <img src={oyo} alt='nse' />
                                        </div>
                                        <div className='stock-card-details-con'>
                                            <h3>NSE India Limited Unlisted Shares</h3>
                                            <h4>₹6500</h4>
                                            <div className='stock-card-details-info-con'>
                                                <h5>( +0.00 )</h5>
                                                <h5>( 0% )</h5>
                                                <h6>15D</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        {/* <div className='Home-section-3-stocks-btn-con'>
                            <button>
                                View More
                            </button>
                        </div> */}
                        <div className='home-section-3-stock-pagination-con'>
                            <Pagination
                                hideOnSinglePage
                                current={page}
                                total={total}
                                pageSize={limit}
                                onChange={(page) => setPage(page)}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeProduct